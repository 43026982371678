/* eslint-disable filenames/match-regex */
import React from 'react';
import { MediaSwitzerland } from '../../../../components/view/media-switzerland';
import { ExternalData } from '../../../../templates/external-data';
import { ExternalDataProps } from '../../../../templates/types';

const MediaSwitzerlandPage = (props: ExternalDataProps) => <ExternalData>
  <MediaSwitzerland {...props} />
</ExternalData>;

// eslint-disable-next-line import/no-default-export
export default MediaSwitzerlandPage;
